import { defineMessages } from 'react-intl'

const key = 'containers.home.header'

const messages = defineMessages({
  logoDescription: {
    id: `${key}.logoDescription`,
    defaultMessage: `Couture Hunting's logo`,
  },
})

export default messages
