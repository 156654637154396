import React from 'react'

import Home from '../containers/Home'

import SEO from '../components/SEO'

function IndexPage() {
  return (
    <div className="homepage">
      <SEO title="Home" />

      <Home />
    </div>
  )
}

export default IndexPage
