import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Garment from '../Garment'

import './styles.scss'

function Garments({ garments }) {
  return (
    <div className="garments">
      {garments.map(garment => (
        <Garment
          key={`garment-${garment.id}`}
          garment={garment}
        />
      ))}
    </div>
  )
}

Garments.propTypes = {
  garments: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default memo(Garments)
