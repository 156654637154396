import { defineMessages } from 'react-intl'

const key = 'containers.home.menu'

const messages = defineMessages({
  designers: {
    id: `${key}.designers`,
    defaultMessage: `Designers`,
  },
  aboutUs: {
    id: `${key}.aboutUs`,
    defaultMessage: `About us`,
  },
})

export default messages
