import React, { memo } from 'react'
import { injectIntl, intlShape } from 'gatsby-plugin-intl'

import Menu from '../Menu'

import messages from './messages'

import Logo from '../../../../images/couture-hunting-logo.svg'

import './styles.scss'

function Header({ intl }) {
  return (
    <header className="home-header">
      <div className="home-header__logo-wrapper">
        <img
          src={Logo}
          className="home-header__logo"
          alt={intl.formatMessage(messages.logoDescription)}
        />
      </div>

      <Menu />
    </header>
  )
}

Header.propTypes = {
  intl: intlShape.isRequired,
}

export default memo(injectIntl(Header))
