import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classnames from 'classnames'

import './styles.scss'

function LinkButton({ title, to, className }) {
  return (
    <div className={classnames("link-button", className)}>
      <Link to={to}>
        <span>{title}</span>
      </Link>
    </div>
  )
}

LinkButton.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default memo(LinkButton)
