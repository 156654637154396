import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Homepage from './components/Page'

function Home() {
  const { allContentfulGarment } = useStaticQuery(
    graphql`
      query getLatestGarments {
        allContentfulGarment(
          limit: 20
          sort: { order: DESC, fields: createdAt }
        ) {
          edges {
            node {
              id
              name
              createdAt
              image {
                description
                file {
                  fileName
                  url
                }
              }
            }
          }
        }
      }
    `,
  )
  const garments = allContentfulGarment.edges.map(({ node }) => node)

  return (
    <div className="home-container">
      <Homepage garments={garments} />
    </div>
  )
}

export default Home
