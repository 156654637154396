import React from 'react'
import PropTypes from 'prop-types'

import Header from '../Header'
import Garments from '../Garments'

import './styles.scss'

function Page({ garments }) {
  return (
    <div className="home">
      <Header />

      <div className="home-content">
        <Garments garments={garments} />
      </div>
    </div>
  )
}

Page.propTypes = {
  garments: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Page
