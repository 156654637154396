import React, { memo, useEffect } from 'react'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import { injectIntl, intlShape } from 'gatsby-plugin-intl'
import classnames from 'classnames'

import messages from './messages'

import { HOME_HEADER_ANGLE_IN_DEGREES, HOME_HEADER_ANGLE_IN_RADIANS } from '../../core/constants'

import './styles.scss'

const MENU_LINKS = [
  { name: 'designers', labelKey: 'designers', path: 'designers' },
  { name: 'about-us', labelKey: 'aboutUs', path: 'about-us' },
]

function Menu({ intl }) {
  useEffect(() => {
    let lastKnownScrollPosition = 0
    let ticking = false

    const moveMenu = (scrollPosition) => {
      const menuEl = window.document.querySelector('.home-menu')
      const speedDecreaseFactor = 2
      const xPos = `${Math.round(Math.cos(HOME_HEADER_ANGLE_IN_RADIANS) * scrollPosition / speedDecreaseFactor)}px`
      const yPos = `${Math.round(Math.sin(HOME_HEADER_ANGLE_IN_RADIANS) * scrollPosition / speedDecreaseFactor)}px`

      menuEl.style.transform = `translate(${xPos}, ${yPos}) rotate(${HOME_HEADER_ANGLE_IN_DEGREES}deg)`
    }

    const handleScrollChange = () => {
      lastKnownScrollPosition = window.scrollY

      if (!ticking) {
        window.requestAnimationFrame(function() {
          moveMenu(lastKnownScrollPosition)
          ticking = false
        })
      }

      ticking = true
    }

    window.addEventListener('scroll', handleScrollChange)

    return () => {
      window.removeEventListener('scroll', handleScrollChange)
    }
  }, [])

  return (
    <nav className="home-menu">
      <Location>
        {({ location }) => {
          return MENU_LINKS.map(({ name, labelKey, path }) => (
            <Link
              className={classnames("home-menu__button", {
                'home-menu__button--selected': location.pathname.includes(path),
              })}
              key={name}
              to={path}
            >
              {intl.formatMessage(messages[labelKey])}
            </Link>
          ))
        }}
      </Location>
    </nav>
  )
}

Menu.propTypes = {
  intl: intlShape.isRequired,
}

export default memo(injectIntl(Menu))
