import React, { memo } from 'react'
import PropTypes from 'prop-types'

import LinkButton from '../LinkButton'

import './styles.scss'

function Garment({ garment }) {
  return (
    <div className="garment">
      <img
        src={`https:${garment.image.file.url}?q=50`}
        alt={garment.image.description}
        className="garment-image"
      />

      <div className="garment-actions">
        <LinkButton
          className="garment-action"
          title="view"
          to={`/garments/${garment.id}`}
        />
      </div>
    </div>
  )
}

Garment.propTypes = {
  garment: PropTypes.object.isRequired,
}

export default memo(Garment)
